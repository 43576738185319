
body {
  background-color: #1a1a1a;
  min-height: 100vh;
  padding-bottom: 20px;
}

h4 {
  text-align: left; /* Alignement à gauche */
  color: #ffffff;
  margin: 10px 0; /* Espacement vertical */
  font-weight: bold;
  font-size: 2.0em; /* Taille de police pour la version web */
}

/* Taille de police pour les petits écrans */
@media (max-width: 768px) {
  h4 {
    font-size: 1.5em; /* Taille de police pour la version responsive */
  }
}
/* -----------------------START VIGNETTE HOME --------------------------------*/
.shop-page-header {
  text-align: center; /* Centre le texte horizontalement */
  color: #ffffff; /* Couleur du texte (ajuste selon tes besoins) */
  margin: 20px 0; /* Espace autour du titre et du sous-titre */
}

.shop-page-header h1 {
  font-size: 2em; /* Taille du titre principal */
  margin-bottom: 10px; /* Espace sous le titre */
}

.shop-page-header p {
  font-size: 1.1em; /* Taille du sous-titre */
  line-height: 1.5; /* Espacement entre les lignes pour lisibilité */
}

.vignettes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Pour afficher 3 colonnes */
  gap: 10px; /* Espace entre les vignettes */
  max-width: 1200px; /* Largeur maximale */
  margin: 8px auto; /* Centrer le contenu */
  padding: 0 20px; /* Rembourrage */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .vignettes-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur petits écrans */
  }
}

@media (min-width: 480px) {
  .vignettes-grid {
    grid-template-columns: 1fr; /* 1 colonne sur très petits écrans */
  }
}

.vignette {
  background-color: #333; /* Couleur de fond des vignettes */
  border-radius: 8px; /* Coins arrondis */
  overflow: hidden; /* Masque le débordement */
  text-align: center; /* Centre le texte */
  display: flex; /* Utilise flex pour aligner les éléments internes */
  flex-direction: column; /* Colonne pour les vignettes */
  justify-content: space-between; /* Espace entre les éléments internes */
}

.vignette img {
  width: 100%; /* Prend toute la largeur */
  max-height: 200px; /* Hauteur maximale */
  object-fit: cover; /* Recadre l'image */
  border-radius: 8px; /* Coins arrondis pour l'image */
}

.vignette-content {
  padding: 10px; /* Espace à l'intérieur de la vignette */
  color: #fff; /* Couleur du texte */
}

.vignette-rank,
.vignette-views {
  font-size: 0.8em; /* Taille de police */
}

.vignette-description {
  font-size: 1.1em;
  margin-bottom: 1.0vw; /* Taille de police pour la description */
}

.vignette:hover {
  transform: scale(1.04);
}
/*-------------------FIN VIGNETTE HOME --------------------------------------*/

/* -----------------------START SHOP VIGNETTES ITEMS --------------------------------*/

.shop-page .vignettes-grid-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Pour afficher 3 colonnes */
  gap: 25px; /* Espace entre les vignettes */
  max-width: 1200px; /* Largeur maximale */
  margin: 8px auto; /* Centrer le contenu */
  padding: 0 40px; /* Rembourrage */
  margin-bottom: 2rem;
}

/* Responsive adjustments pour petits écrans */
@media (max-width: 768px) {
  .shop-page .vignettes-grid-items {
    grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur petits écrans */
  }
}

@media (max-width: 480px) {
  .shop-page .vignettes-grid-items {
    grid-template-columns: 1fr; /* 1 colonne sur très petits écrans */
  }
}

.shop-page .vignette.items {
  background-color: #333;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Permet à la vignette d'adapter sa hauteur en fonction de son contenu */
}

.shop-page .vignette-img.items {
  width: 80%;   /* Réduit la largeur à 80% */
  height: 80%; /* Garde le ratio de l'image */
  max-height: 120%; /* Hauteur maximale à 120% */
  object-fit: cover; /* Remplit la vignette sans déformation */
  display: block; /* Supprime les marges internes */
  margin: 0 auto; /* Centrer l'image horizontalement */
}


.shop-page .vignette-content.items {
  padding: 10px; /* Espace à l'intérieur de la vignette */
  color: #fff;
}

.shop-page .vignette-rank.items {
  font-size: 1.1rem; /* Taille de police */
  font-weight: bold; /* Met en gras */
  color: #ffa31a; /* Change la couleur du texte */
  margin-bottom: 10px; /* Espace en bas */
}

.shop-page .vignette-description.items {
  font-size: 1.0em;
  margin-bottom: 0vw; /* Taille de police pour la description */
  text-align: justify; /* Justifie le texte */
}

.shop-page .vignette:hover.items {
  transform: scale(1.04);
}

.shop-page .vignette-category.items {
  background-color: rgba(255, 163, 26, 0.8); /* Couleur de fond avec transparence */
  color: #000000; /* Couleur du texte */
  padding: 6px; /* Espace autour du texte */
  text-align: center; /* Centrer le texte */
  font-weight: 600; /* Gras pour le texte */
  font-weight: bold; /* Gras pour le texte, utilisé pour un affichage en gras */
  font-size: 1.1em; /* Taille de police */
  border-radius: 4px; /* Coins légèrement arrondis */
  margin-top: 1px; /* Espace au-dessus */
  display: inline-block; /* Pour que la couleur ne dépasse pas la taille du texte */
}


/*-------------------FIN SHOP VIGNETTES ITEMS --------------------------------------*/



/* POPUP CHANGEMENT TAILLE ET COULEUR */

/* Style pour le titre */
.age-verification-title {
  font-size: 2.5em; /* Ajuste la taille si nécessaire */
  text-align: center;
  color: #ffa31a; /* Couleur du texte */
  margin-bottom: 10px; /* Réduit l'espace en dessous du titre */
}

/* Style pour le paragraphe */
.age-verification-content {
  font-size: 1em;
  text-align: justify;
  margin-top: 0; /* Réduit l'espace au-dessus du paragraphe */
  padding-bottom: 0; /* Ajuste l'espace au-dessous si nécessaire */
}

.popup-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.popup-content {
  background-color: rgba(40, 40, 40, 0.9);
  padding: 20px !important; /* Taille hauteur popup */
  border-radius: 10px;
  text-align: center;
  max-width: 600px; /* Largeur maximale pour le popup */
  width: 90%; /* Largeur responsive */
  overflow: hidden; /* Empêche le débordement */
}

.parental-info {
  margin-top: 20px; /* Ajustez cette valeur selon vos besoins */
}






/* ----------------------TITRE CONFIG PAGE HOME ----------------------------------------*/


/*----------------------------------------------------------------*/
@media (min-width: 768px) {
  .titlehomepage1 {
    font-size: 1.4rem; /* Ajuste la taille du texte pour la version bureau */
    color: #ffffff; /* Couleur du texte */
    margin-left: 23.2rem; /* Ajustez cette valeur pour déplacer le titre vers la droite */
    margin-top: 0.5rem; /* Espace au-dessus du titre */
    margin-bottom: 0.5rem; /* Espace en dessous du titre */
    text-align: left; /* Aligne le texte à gauche */
  }
}

@media (max-width: 768px) {
  .titlehomepage1 {
    font-size: 0.9rem; /* Ajuste la taille du texte pour la version bureau */
    color: #ffffff; /* Couleur du texte */
    margin-left: 1.5rem; /* Ajustez cette valeur pour déplacer le titre vers la droite */
    margin-top: 0.5rem; /* Espace au-dessus du titre */
    margin-bottom: 0.5px; /* Espace en dessous du titre */
    text-align: left; /* Aligne le texte à gauche */
  }
}




/*----------------------------------------------------------------*/

/* ----------------------TITRE CONFIG PAGES CATEGORY ----------------------------------------*/
.title-container {
  display: flex; /* Utiliser flexbox */
  justify-content: center; /* Centrer horizontalement */
  align-items: center; /* Centrer verticalement si besoin */
  padding: 10px; /* Ajouter un peu d'espace autour */
}

.titlecatpage1 {
  font-size: 1.0rem;
  color: #ffffff;
  margin: 0; /* Supprimez les marges spécifiques pour éviter les mouvements */
}

/* ----------------------------Pour les écrans plus petits ---------------------------------*/
@media (max-width: 768px) {
  .titlecatpage1 {
    font-size: 1.0rem;
    color: #ffffff;
    margin: 0; /* Supprimez les marges spécifiques */
    padding-left: 1.4rem; /* Décaler le texte vers la droite */
    margin-top: 12px; /* Espace au-dessus du titre */
    margin-bottom: -0.4rem; /* Espace en dessous du titre */
  }
}

/* ----------------------------Pour les écrans 12", 13", 14", 15", et 16" ---------------------------------*/
@media (min-width: 1280px) and (max-width: 1440px) { /* Pour les écrans 12" et 13" */
  .titlecatpage1 {
    font-size: 1.2rem; /* Ajuster la taille pour 12" et 13" */
    margin-left: 0; /* Aligne le titre à gauche de Rank 1 */
    margin-top: 10px; /* Espace au-dessus du titre */
    margin-bottom: 1px; /* Espace en dessous du titre */
    white-space: nowrap; /* Empêche le texte de se diviser en plusieurs lignes */
    overflow: hidden; /* Cache tout dépassement de texte */
    text-overflow: ellipsis; /* Ajoute des points de suspension si le texte dépasse */
    width: auto; /* Ajuste la largeur pour le conteneur */
    
  }
}

@media (min-width: 1440px) and (max-width: 1600px) { /* Pour les écrans 14" */
  .titlecatpage1 {
    font-size: 1.3rem; /* Ajuster la taille pour 14" */
    margin-left: 0; /* Aligne le titre à gauche de Rank 1 */
    margin-top: 10px; /* Espace au-dessus du titre */
    margin-bottom: 1px; /* Espace en dessous du titre */
  }
}

/*------------------------------- Cibler l'iPad Pro --------------------------------*/
@media (min-width: 1024px) and (max-width: 1366px) {
  .titlecatpage1 {
      font-size: 1.5rem; /* Ajuste la taille du texte pour l'iPad Pro */
      color: #ffffff;
      text-align: left;
      margin-top: 1rem;
      margin-left: 5rem; /* Ajustement des marges */
      margin-right: 5rem;
  }

  .vignettes-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); /* Trois colonnes sur l'iPad Pro */
      gap: 16px; /* Espacement modéré */
  }

  .vignette {
      height: 280px; /* Hauteur adaptée à l'écran de l'iPad Pro */
  }
}
/*----------------------------------------------------*/

@media (min-width: 1600px) and (max-width: 1920px) { /* Pour les écrans 15" */
  .titlecatpage1 {
    font-size: 1.4rem; /* Ajuster la taille pour 15" */
    margin-left: 0; /* Aligne le titre à gauche de Rank 1 */
    margin-top: 10px; /* Espace au-dessus du titre */
    margin-bottom: 1px; /* Espace en dessous du titre */
  }
}

@media (min-width: 1920px) { /* Pour les écrans 16" */
  .titlecatpage1 {
    font-size: 1.5rem; /* Ajuster la taille pour 16" */
    margin-left: 0; /* Aligne le titre à gauche de Rank 1 */
    margin-top: 10px; /* Espace au-dessus du titre */
    margin-bottom: 1px; /* Espace en dessous du titre */
  }
}


/*------------------------------- Cibler la version bureau --------------------------------*/
@media (min-width: 768px) {
  .titlecatpage1 {
    font-size: 1.6rem; /* Ajuste la taille du texte pour la version bureau */
    color: #ffffff; /* Couleur du texte */
    text-align: center; /* Centrer le texte */
    margin-top: 1rem;
    margin-right: 62svh;
  }
}


/* -----------START COULEUR DE LA BAR TIMER RANK ----------------*/
.custom-bar-background {
  background-color: #ffa31a; /* Couleur verte personnalisée */
}

/* -----------FIN COULEUR DE LA BAR TIMER RANK ----------------*/

/* -----------START COULEUR FOND BOUTON SHOP ----------------*/
.shop-btn {
  background-color: #e0e0e0; /* Couleur de fond par défaut */
  color: #000000; /* Couleur du texte par défaut */
  border: none; 
  font-size: 16px; 
  font-weight: 550; 
  padding: 7px 32px; 
  border-radius: 10px; 
  transition: background-color 0.3s, color 0.3s; /* Transition douce pour le changement de couleur */
}

.shop-btn:hover {
  background-color: #4CAF50; /* Couleur de fond au survol (vert) */
  color: #ffffff; /* Couleur du texte lors du survol */
}
/* ------------FIN COULEUR FOND BOUTON SHOP ----------------*/
/* styles.css */

/* Styles de la barre de séparation */
.separator {
  background: linear-gradient(to right, #ffa31a, #ffa31a);
  height: 19px;
  margin: 5px auto;
  border-radius: 5px;
  width: 61%;
  animation: shine 2s infinite; /* Animation de brillance */
}

/* Styles spécifiques pour les écrans mobiles */
@media (max-width: 768px) {
  .separator {
    width: 80%; /* Augmenter la largeur pour les écrans plus petits */
    height: 15px; /* Ajuster la hauteur si nécessaire */
  }
}

/* Animation de brillance */
@keyframes shine {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

/* Styles des boutons */
.category-buttons {
  display: flex;
  justify-content: center; /* Centrer les boutons */
  flex-wrap: wrap; /* Permettre le retour à la ligne des boutons si nécessaire */
  margin-top: 20px;
}

.category-button {
  padding: 10px 15px;
  margin: 5px; /* Espace entre les boutons */
  margin-top: -0.1rem;
  background-color: #000000;
  color: #ffffff;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s;
  flex: 0 1 auto; /* Ne pas forcer l'expansion des boutons */
}

/* Effet de survol pour les boutons */
.category-button:hover {
  background-color: #e59400;
}

/* Styles spécifiques aux écrans de bureau */
@media (min-width: 768px) {
  .category-button {
    padding: 10px 20px; /* Plus de padding sur les écrans de bureau */
    font-size: 1.1em; /* Légèrement plus grand */
  }
}

/* Styles spécifiques aux écrans mobiles */
@media (max-width: 767px) {
  .category-button {
    padding: 8px 12px; /* Moins de padding sur mobile */
    font-size: 1em; /* Taille de police réduite */
  }
}
