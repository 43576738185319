/* Styles généraux */
.advertiser-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
    background: #1a1a1a;
    color: #ffffff;
    border-radius: 16px;
    box-shadow: 0 0 20px rgba(255, 163, 26, 0.5);
    animation: fadeIn 1.2s ease-in-out;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Titres */
  .main-title {
    font-size: 2.8rem;
    color: #ffa31a;
    margin-bottom: 20px;
    text-shadow: 0 0 10px #ffa31a, 0 0 20px #ff7f00;
  }
  
  .subtitle {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #ffcc80;
  }
  
  .description {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #e0e0e0;
    line-height: 1.6;
  }
  
  /* Modules */
  .calendar-module,
  .payment-module,
  .contact-form,
  .stats-module {
    width: 100%;
    margin-bottom: 30px;
    background: #2a2a2a;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
  }
  
  h3 {
    color: #ffa31a;
    text-shadow: 0 0 10px #ffa31a;
  }
  
  /* Champs de formulaire */
  .date-picker {
    margin: 10px 0;
  }
  
  input[type="email"],
  select {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border-radius: 12px;
    border: none;
    background: #3a3a3a;
    color: #ffa31a;
    font-size: 1rem;
    box-shadow: 0 0 10px rgba(255, 163, 26, 0.3);
  }
  
  /* Bouton de soumission */
  .submit-btn {
    width: 100%;
    padding: 15px;
    background-color: #ffa31a;
    color: #1a1a1a;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.3s;
    box-shadow: 0 0 15px #ffa31a;
  }
  
  .submit-btn:hover {
    background-color: #ff7f00;
    box-shadow: 0 0 20px #ff7f00;
  }
  
  /* Estimation du prix */
  .price-estimate {
    font-size: 1.5rem;
    color: #ffa31a;
    margin-top: 15px;
    text-shadow: 0 0 10px #ffa31a;
  }
  
  /* Module des statistiques */
  .stats-module {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .chart-container {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
  
  /* Styles pour mobile */
  @media (max-width: 600px) {
    .main-title {
      font-size: 2rem;
    }
  
    .subtitle {
      font-size: 1.4rem;
    }
  
    .description {
      font-size: 1rem;
    }
  
    .calendar-module,
    .payment-module,
    .contact-form,
    .stats-module {
      padding: 15px;
    }
  
    .submit-btn {
      font-size: 1rem;
    }
  
    .chart-container {
      height: 250px;
    }
  }
  
  /* Styles pour tablette */
  @media (min-width: 601px) and (max-width: 1024px) {
    .main-title {
      font-size: 2.4rem;
    }
  
    .subtitle {
      font-size: 1.6rem;
    }
  
    .description {
      font-size: 1.1rem;
    }
  
    .calendar-module,
    .payment-module,
    .contact-form,
    .stats-module {
      padding: 20px;
    }
  
    .chart-container {
      height: 280px;
    }
  }
  
  /* Styles pour desktop */
  @media (min-width: 1025px) {
    .main-title {
      font-size: 3rem;
    }
  
    .subtitle {
      font-size: 2rem;
    }
  
    .description {
      font-size: 1.3rem;
    }
  
    .chart-container {
      height: 350px;
    }
  }
  