/* Styles pour la page Shop */
.shop-page {
    text-align: center;
    color: #333;
    padding-top: 20px;
  }
  
  .shop-page-header {
    margin-bottom: 30px;
  }
  
  .shop-page-header h1 {
    margin-top: -1rem;
    font-size: 2.5rem;
    color: #f5f1f1;
  }
  
  .shop-page-header p {
    font-size: 1.2rem;
    color: #8d8989;
    margin-bottom: -1rem;
  }
  
  /* Grille des vignettes */
  .vignettes-grid-items {
    display: grid;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Styles pour chaque vignette */
  .vignette {
    display: block;
    background-color: #333;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    color: white;
    transition: transform 0.2s;
  }
  
  .vignette:hover {
    transform: scale(1.05);
  }
  
  .vignette-img {
    width: 100%;
    height: 200px; /* Ajuste cette valeur pour le visuel désiré */
    object-fit: cover;
  }
  
  .vignette-content {
    padding: 15px;
  }
  
  .vignette-rank {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Nouvelle définition de la description pour afficher tout le texte */
  .vignette-description {
    margin: 10px 0; /* Espacement en haut et en bas */
    font-size: 1rem;
    color: #ccc;
    height: 100px; /* Définit une hauteur fixe pour chaque vignette */
    overflow-y: auto; /* Active le défilement vertical si le texte dépasse la hauteur */
    overflow-x: hidden; /* Masque le texte qui déborde horizontalement */
    text-overflow: ellipsis; /* Ajoute une ellipse si le texte est trop long */
    display: block; /* Supprime la limitation de lignes pour afficher tout le texte */
    padding: 10px; /* Ajoute un peu de rembourrage pour le confort de lecture */
    padding-bottom: 30px;
    background-color: rgba(51, 51, 51, 0.8); /* Fond semi-transparent pour la lisibilité */
    border-radius: 5px; /* Arrondit les coins */
    transition: background-color 0.3s; /* Transition pour un effet au survol */
  }

  /* Style de la scrollbar */
.vignette-description::-webkit-scrollbar {
    width: 8px; /* Largeur de la barre de défilement */
}

/* Fond de la scrollbar */
.vignette-description::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2); /* Couleur de l'arrière-plan de la barre de défilement */
}
  
  /* Ajouter un effet de survol de la description pour plus de style */
  .vignette-description:hover {
    background-color: rgba(51, 51, 51, 0.8); /* Change la couleur de fond au survol */
  }
  
  .vignette-category {
    background-color: #444;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    color: #fff;
  }
  