.blog-page {
  min-height: 100vh;
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.blog-header {
  text-align: center;
  margin-bottom: 40px;
}

.blog-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.blog-header p {
  font-size: 1.2rem;
  color: #cccccc;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  background-color: #333333;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.blog-card:hover {
  transform: scale(1.05);
}

.blog-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
}

.blog-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffa31a;
}

.blog-date {
  font-size: 0.9rem;
  color: #888888;
  margin-bottom: 10px;
}

.blog-summary {
  font-size: 1rem;
  margin-bottom: 15px;
}

.read-more {
  background-color: #ffa31a;
  color: #1a1a1a;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #cc8400;
}

.blog-footer {
  text-align: center;
  margin-top: 40px;
  padding: 10px 0;
  border-top: 1px solid #444444;
  font-size: 0.9rem;
}
