/* Styles généraux pour la page des vignettes */
.container {
    min-height: 100vh;
    background-color: #1a1a1a;
    padding-bottom: 20px;
  }


  /*------------------------------- Cibler la version bureau --------------------------------*/
@media (min-width: 768px) {
    .titlehomepage1 {
      font-size: 1.6rem; /* Ajuste la taille du texte pour la version bureau */
      color: #ffffff; /* Couleur du texte */
      text-align: left; /* Centrer le texte */
      margin-top: 1rem;
      margin-left: 24rem;
      
    }
  }

  /*------------------------------- Cibler l'iPad Pro --------------------------------*/
@media (min-width: 1024px) and (max-width: 1366px) {
    .titlehomepage1 {
        font-size: 1.3rem; /* Ajuste la taille du texte pour tenir sur une ligne */
        color: #ffffff;
        text-align: left;
        margin-top: 1rem;
        margin-left: 3rem; /* Réduit les marges pour plus d'espace */
        margin-right: 3rem; /* Réduit les marges pour plus d'espace */
        white-space: nowrap; /* Empêche le texte de passer à la ligne */
        overflow: hidden; /* Assure que le texte ne déborde pas */
        text-overflow: ellipsis; /* Ajoute des points de suspension si le texte dépasse */
    }
}

  
  /* Grille pour les vignettes */
  .vignette-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 10px;
    justify-content: center;
    align-items: stretch;
  }
  
  /* Styles pour chaque vignette */
  .vignette-card {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .vignette {
    background-color: #333;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    cursor: pointer;
  }
  
  .vignette-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .vignette-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    z-index: 1;
  }
  
  /* Contenu de la vignette */
  .vignette-content {
    padding: 12px;
    position: relative;
    z-index: 2;
  }
  
  .vignette-rank {
    font-weight: 600;
    color: #ffffff;
    font-size: 0.9em;
    margin-bottom: 8px;
  }
  
  .vignette-info {
    font-size: 0.9em;
    line-height: 1.4;
  }
  
  /* Wrapper pour la catégorie */
  .vignette-category-wrapper {
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333333;
    color: #ffffff;
    padding: 2px;
    text-align: left;
    z-index: 2;
  }
  
  .vignette-category {
    font-weight: 250;
    font-size: 1.6em;
  }
  
  /* Style pour la barre de séparation */
  .separator {
    background: linear-gradient(to right, #ffa31a, #ffa31a);
    height: 20px;
    border: 20px;
    margin: 20px auto;
    border-radius: 5px;
    width: 61%;
    animation: shine 2s infinite;
  }
  
  @keyframes shine {
    0% { opacity: 0.7; }
    50% { opacity: 1; }
    100% { opacity: 0.7; }
  }
  