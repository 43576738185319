/* CSS pour le header */
.custom-header {
  text-align: center;
  padding: 0 !important;
  background-color: #000000;
  position: relative;
  height: auto !important;
  min-height: 0 !important;
}

/* CSS pour le lien contenant le logo */
.custom-logo-link {
  display: inline-block;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
}

/* CSS pour l'image du logo */
.responsive-logo {
  max-width: 100rem !important; /* Assure que l'image ne dépasse pas la largeur de son conteneur */
  height: 100rem !important; /* Conserve les proportions de l'image */
  max-height: 150px !important; /* Limite la hauteur maximale de l'image à 150px */
  margin-bottom: -1rem !important;
  margin-top: -1rem !important;
}





/* Autres styles ... */

.responsive-logo {
  max-width: 100%; /* S'assure que l'image ne dépasse pas la largeur de son conteneur */
  height: auto; /* Conserve les proportions de l'image */
  max-height: 150px; /* Limite la hauteur maximale */
  display: block; /* Évite l'espace en bas de l'image */
  margin: 0 auto; /* Centre l'image horizontalement */
}

header {
  padding: 0.5rem 0; /* Réduit les bords noirs (padding en haut et en bas) */
  background-color: #000000; /* Garde le fond noir */
  text-align: center; /* Centre le contenu */
}

/* ------------------------------------------------------------------------------*/


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(238, 219, 219);
}

.App-link {
  color: #ffa31a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  min-height: 100vh; /* Définit la hauteur minimale du body à 100% de la hauteur de la fenêtre */
  margin: 0; /* Enlève la marge par défaut */
  padding: 0; /* Enlève le padding par défaut */
  background-color: #1a1a1a; /* Exemple de couleur de fond */
}

/* Styles pour le bouton X */
.x-btn {
  background-color: transparent; 
  position: absolute; 
  right: 20vw; /* Position pour version web */
  top: 50%; 
  transform: translateY(-50%); 
}

@media (max-width: 768px) {
  .x-btn {
    position: relative; /* Supprime la position absolue pour mobile */
    right: 10; /* Réinitialise la position à droite */
    top: 0; /* Réinitialise la position vers le haut */
    transform: none; /* Annule le transform */
  }
}

/* Vignette styles */
.vignette img {
  width: 100%; /* Assure que l'image prend toute la largeur de la vignette */
  height: 200px; /* Définit une hauteur fixe pour toutes les images */
  object-fit: cover; /* Recadre l'image pour qu'elle remplisse le conteneur */
  border-radius: 8px; /* Facultatif : pour arrondir les coins des images */
}

/* Footer --- */

/* Styles de la barre de séparation */
.separator {
  background: linear-gradient(to right, #ffa31a, #ffa31a); /* Dégradé de couleur */
  height: 19px; /* Hauteur de la barre */
  margin: 5px auto; /* Marges automatiques pour centrer */
  border-radius: 5px; /* Bords arrondis */
  width: 61%; /* Largeur de la barre */
  animation: shine 2s infinite; /* Animation de brillance continue */
}

/* Styles généraux pour le footer */
footer {
  padding: 20px; /* Espacement interne du footer */
}

/* Styles spécifiques pour le formulaire d'inscription */
footer form {
  display: flex; /* Utiliser flexbox pour l'alignement */
  flex-direction: column; /* Aligner les éléments en colonne */
  align-items: center; /* Centrer horizontalement les éléments */
  margin: 10px 0; /* Espacement vertical entre les éléments */
  
}

footer input[type="email"] {
  padding: 10px; /* Espacement interne pour le champ de saisie */
  border-radius: 5px; /* Bords arrondis du champ */
  border: 1px solid #ffa31a; /* Bordure de couleur */
  width: 100%; /* Prendre toute la largeur disponible */
  max-width: 250px; /* Largeur maximale pour le champ */
  margin-bottom: 10px; /* Espacement en bas */
  outline: none; /* Supprimer le contour par défaut */
  font-size: 14px; /* Taille de la police */
}

footer button {
  padding: 10px 15px; /* Espacement interne du bouton */
  border-radius: 5px; /* Bords arrondis du bouton */
  border: none; /* Supprimer la bordure par défaut */
  background-color: #ffa31a; /* Couleur de fond du bouton */
  color: #fff; /* Couleur du texte */
  cursor: pointer; /* Changer le curseur au survol */
  font-size: 14px; /* Taille de la police */
  transition: background-color 0.3s; /* Transition de couleur au survol */
}

/* Styles au survol du bouton */
footer button:hover {
  background-color: #e68a00; /* Couleur de fond au survol */
}

/* Styles responsives */
@media (max-width: 600px) {
  footer input[type="email"] {
    width: 90%; /* Réduire la largeur du champ sur les petits écrans */
  }

  footer button {
    width: 90%; /* Le bouton prend également 90% de la largeur */
  }
}



