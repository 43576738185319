/* MapPage.css */
.separator {
  border-top: 2px solid #f1f1f1;
  margin: 20px 0;
}

.category-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.category-button {
  background-color: #444;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.category-button:hover {
  background-color: #ffc107;
}

.titlecatpage1 {
  text-align: center;
  color: #ffffff;
  font-size: 1.8em;
  margin-top: 20px;
}

