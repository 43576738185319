/* SoundPornVignettes.css */

.SoundPornVignettes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px; /* Espacement entre les vignettes */
    justify-content: center; /* Centrer horizontalement les vignettes */
    align-items: stretch; /* Étirer les vignettes pour qu'elles aient toutes la même hauteur */
    margin: 0 auto; /* Centrer le conteneur */
    max-width: 1200px; /* Limiter la largeur maximale pour une meilleure mise en page */
  }
  
  .vignette {
    background-color: #333; /* Couleur de fond des vignettes */
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s; /* Animation lors du survol */
  }
  
  .vignette:hover {
    transform: scale(1.05); /* Zoom léger lors du survol */
  }
  
  .vignette-content {
    padding: 12px;
    color: #ffffff; /* Couleur du texte */
  }
  
  .vignette-category {
    background-color: rgba(51, 51, 51, 0.8); /* Légère transparence pour la catégorie */
    padding: 2px;
    text-align: left;
    font-weight: 500; /* Épaisseur du texte */
    font-size: 1.2em; /* Taille du texte */
  }
  